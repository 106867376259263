<template>
  <Form inline :label-width="120">
    <FormItem style="margin-bottom: 0">
      <span slot="label" class="validate">议事投票时限:</span>
      <DatePicker
        type="datetimerange"
        v-model="discussAnnTime"
        format="yyyy-MM-dd HH:mm"
        placeholder="请选择议事公告开始时间和结束时间"
        style="width: 350px"
        transfer
      ></DatePicker>
    </FormItem>
    <FormItem>
      <span slot="label" class="validate">评论留言:</span>
      <i-switch
        size="large"
        v-model="annFormData.chatDiscuss"
        true-value="ON"
        false-value="OFF"
      >
        <span slot="open">开启</span>
        <span slot="close">关闭</span>
      </i-switch>
    </FormItem>
    <!-- <iframe
      name="ueditor"
      id="ueditor"
      src="/ueditor/index.html?titlename=公告内容（必填）"
      style="width: 100%; height: calc(100vh - 235px); border: 0px"
    ></iframe>  -->
    <iframe
         name="ueditor"
         id="ueditor"
         src="/mobileeditor/#/index?titlename=公告内容（必填）"
        style="width: 100%; height: calc(100vh - 235px); border: 0px"
     ></iframe>
  </Form>
</template>

<script>
export default {
  props: ["parentBusinessId"],
  data() {
    return {
      discussAnnTime: [],
      annFormData: {
        chatDiscuss: "ON",
      },
    };
  },
  created(){
    sessionStorage.setItem('NODEENV',process.env.NODE_ENV)
  },
  methods: {
    save() {
      if (this.discussAnnTime[0] == "" || this.discussAnnTime[1] == "") {
        this.$Message.error({
          background: true,
          content: "请选择议事公告开始时间和结束时间",
        });
        return;
      }
      let v_Obj = document.getElementById("ueditor").contentWindow;
      this.annFormData.content = v_Obj.getContent();
      if (
        this.annFormData.content == "" ||
        this.annFormData.content == undefined ||
        this.annFormData.content == null
      ) {
        this.$Message.error({
          background: true,
          content: "请填写公告内容",
        });
        return;
      }
      this.$Message.loading({
        content: "正在保存，请稍等...",
        duration: 0,
      });
      this.$post("/sendmsg/pc/chat/discussBusiness/addNextNoticeBusiness", {
        ...this.annFormData,
        startTime: this.$core.formatDate(
          new Date(this.discussAnnTime[0]),
          "yyyy-MM-dd hh:mm:ss"
        ),
        endTime: this.$core.formatDate(
          new Date(this.discussAnnTime[1]),
          "yyyy-MM-dd hh:mm:ss"
        ),
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        modifier: parent.vue.loginInfo.userinfo.realName,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        parentBusinessId: this.parentBusinessId,
      })
        .then((res) => {
          this.$Message.destroy();
          if (res.code == 200) {
            this.$Message.success({
              background: true,
              content: "保存成功",
            });
            this.$emit('saveNextSuccess');
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.$Message.destroy();
          console.log(err);
          this.$Message.error({
            background: true,
            content: "保存失败，请联系管理员处理",
          });
        });
    },
  },
};
</script>
    
<style scoped lang='less'>
</style>