<template>
  <div>
    <LiefengContent v-if="!$route.query.businessId">
      <template #title>随约议事厅</template>
      <template #toolsbarRight>
        <Input
          search
          enter-button="搜索"
          placeholder="根据标题搜索"
          v-model.trim="title"
          @on-search="search"
          style="width: 240px; margin-right: 4px"
        />
        <Button
          type="primary"
          icon="md-add"
          v-if="buttonRoot == '1001'"
          @click="
            discussNoticeTitle = '新增议事讨论';
            businessType = '1';
            discussNoticeStatus = true;
          "
          >新增议事讨论</Button
        >
        <Button
          type="primary"
          icon="md-add"
          v-if="buttonRoot == '1001'"
          @click="discussVoteStatusFn(true)"
          >新增议事投票</Button
        >
        <Button
          type="primary"
          icon="md-add"
          v-if="buttonRoot == '1001'"
          @click="
            discussNoticeTitle = '新增议事公告';
            businessType = '3';
            discussNoticeStatus = true;
          "
          >新增议事公告</Button
        >
        <Poptip placement="bottom-end" word-wrap width="500">
          <Button type="warning" icon="md-help" style="margin-left: 4px"
            >帮助说明</Button
          >
          <div slot="content" style="text-indent: 2em">
            点击新增会创建一条新的议事，您可以根据实际情况选择创建当前的议事环节；如果该议事已经在进行中，则只需要在对应的议事点击“新增下一阶段”，如有需要同一阶段可以多次开展。
          </div>
        </Poptip>
      </template>
      <template #contentArea>
        <div class="container">
          <Tabs value="name1" @on-click="taggleTab" :animated="false">
            <TabPane label="进行中" name="name1"></TabPane>
            <TabPane label="已结束" name="name2"></TabPane>
          </Tabs>
          <LiefengTable
            :tableData="tableData"
            :talbeColumns="talbeColumns"
            :loading="loading"
            :pageSize="pageSize"
            :pagesizeOpts="[20, 30, 50, 100, 200]"
            :total="total"
            :height="tableHeight"
            :curPage="page"
            @hadlePageSize="hadlePageSize"
          ></LiefengTable>
        </div>
        <!-- 新增议事讨论/议事公告 -->
        <LiefengModal
          :title="discussNoticeTitle"
          :fullscreen="true"
          :value="discussNoticeStatus"
          @input="discussNoticeStatusFn"
          class="discussNotice"
        >
          <template #contentarea>
            <DiscussNotice
              ref="discussNotice"
              @saveSuccess="saveSuccess"
              :businessType="businessType"
              v-if="discussNoticeStatus"
            ></DiscussNotice>
          </template>
          <template #toolsbar>
            <Button
              type="info"
              style="margin-right: 10px"
              @click="
                cancelModalTip = true;
                discussNoticeStatusFn(false);
              "
              >取消</Button
            >
            <Button type="primary" @click="saveDiscussNotice">保存</Button>
          </template>
        </LiefengModal>
        <!-- 新增议事投票 -->
        <LiefengModal
          title="新增议事投票"
          :fullscreen="true"
          :value="discussVoteStatus"
          @input="discussVoteStatusFn"
          class="discussNotice"
        >
          <template #contentarea>
            <Discussvote
              ref="discussVote"
              @changeBackNextText="changeBackNextText"
              @saveSuccess="saveSuccess"
              v-if="discussVoteStatus"
            ></Discussvote>
          </template>
          <template #toolsbar>
            <Button
              type="info"
              style="margin-right: 10px"
              @click="
                cancelModalTip = true;
                discussVoteStatusFn(false);
              "
              >取消</Button
            >
            <Button
              type="primary"
              style="margin-right: 10px"
              @click="backNextFn"
              >{{ backNextText }}</Button
            >
            <Button type="primary" @click="saveDiscussVote">保存</Button>
          </template>
        </LiefengModal>
        <!-- 新增下一阶段——讨论 -->
        <LiefengModal
          title="下阶段议事讨论"
          width="500"
          :value="nextDiscussNoticeStatus"
          @input="
            (status) => {
              nextDiscussNoticeStatus = status;
            }
          "
        >
          <template #contentarea>
            <Form inline :label-width="120">
              <FormItem>
                <span slot="label" class="validate">议事讨论时限:</span>
                <DatePicker
                  type="datetimerange"
                  v-model="discussNoticeTime"
                  format="yyyy-MM-dd HH:mm"
                  placeholder="请选择讨论开始时间和结束时间"
                  style="width: 350px"
                  transfer
                ></DatePicker>
              </FormItem>
            </Form>
          </template>
          <template #toolsbar>
            <Button
              type="info"
              style="margin-right: 10px"
              @click="nextDiscussNoticeStatus = false"
              >取消</Button
            >
            <Button type="primary" @click="saveNextDiscussNotice">确定</Button>
          </template>
        </LiefengModal>
        <!-- 新增下一阶段——投票 -->
        <LiefengModal
          title="下阶段议事投票"
          width="80%"
          height="calc(100vh - 100px)"
          :value="nextDiscussVoteStatus"
          @input="nextDiscussVoteStatusFn"
          class="modal discussNotice"
        >
          <template #contentarea>
            <NextDiscussVote
              v-if="nextDiscussVoteStatus"
              :parentBusinessId="parentBusinessId"
              @saveNextSuccess="saveNextSuccess"
              :buttonRoot="buttonRoot"
              ref="nextDiscussVote"
            ></NextDiscussVote>
          </template>
          <template #toolsbar>
            <Button
              type="info"
              style="margin-right: 10px"
              @click="
                cancelModalTip = true;
                nextDiscussVoteStatusFn(false);
              "
              >取消</Button
            >
            <Button type="primary" @click="saveNextVote">确定</Button>
          </template>
        </LiefengModal>
        <!-- 新增下一阶段——公告 -->
        <LiefengModal
          title="下阶段议事公告"
          width="80%"
          height="calc(100vh - 100px)"
          :value="nextDiscussAnnStatus"
          @input="nextDiscussAnnStatusFn"
          class="modal discussNotice"
        >
          <template #contentarea>
            <NextDiscussAnn
              v-if="nextDiscussAnnStatus"
              :parentBusinessId="parentBusinessId"
              @saveNextSuccess="saveNextSuccess"
              ref="nextDiscussAnn"
            ></NextDiscussAnn>
          </template>
          <template #toolsbar>
            <Button
              type="info"
              style="margin-right: 10px"
              @click="
                cancelModalTip = true;
                nextDiscussAnnStatusFn(false);
              "
              >取消</Button
            >
            <Button type="primary" @click="saveNextAnn">确定</Button>
          </template>
        </LiefengModal>
      </template>
    </LiefengContent>
    <!-- 修改基本内容 -->
    <LiefengModal
      title="议事厅基本信息"
      :fullscreen="true"
      :value="baseInfoStatus"
      @input="baseInfoStatusFn"
      class="modal"
    >
      <template #contentarea>
        <BaseInfo
          ref="baseInfo"
          :startend="startend"
          v-if="baseInfoStatus"
          :buttonRoot="buttonRoot"
          :currentMenuName="currentMenuName"
          :businessId="businessId"
          @saveSuccessBaseInfo="saveSuccessBaseInfo"
          @toggleMenu="(name) => (currentMenuName = name)"
        ></BaseInfo>
      </template>
      <template #toolsbar>
        <Button
          type="success"
          @click="backNextStep"
          style="margin-right: 10px"
          >{{ currentMenuName == "baseInfo" ? "下一步" : "上一步" }}</Button
        >
        <Button
          type="info"
          style="margin-right: 10px"
          @click="
            cancelModalTip =
              $route.query.startend == 'end' || buttonRoot != '1001'
                ? false
                : true;
            baseInfoStatusFn(false);
          "
          >关闭</Button
        >
        <Button
          type="error"
          style="margin-right: 10px"
          @click="openReject(businessId, buttonRoot == '1002' ? '6' : '7')"
          v-if="
            (buttonRoot == '1002' && infoStatus == '2') ||
            (buttonRoot == '1003' && infoStatus == '3')
          "
          >驳回</Button
        >
        <Button
          type="primary"
          @click="saveBaseInfo"
          v-if="startend == 'start' && buttonRoot == '1001'"
          >提交</Button
        >
        <Button
          type="primary"
          @click="updateVoteFn(businessId, buttonRoot == '1002' ? '3' : '4')"
          v-if="
            startend == 'start' &&
            ((buttonRoot == '1002' && infoStatus == '2') ||
              (buttonRoot == '1003' && infoStatus == '3'))
          "
          >{{ buttonRoot == "1002" ? "通过" : "发布" }}</Button
        >
      </template>
    </LiefengModal>
    <!-- 驳回投票 -->
    <LiefengModal
      title="议事驳回"
      :value="rejectStatus"
      @input="rejectStatusFn"
    >
      <template #contentarea>
        <Form
          ref="rejectForm"
          :label-width="100"
          :model="rejectForm"
          :rules="rejectValidate"
        >
          <FormItem label="驳回原因" prop="rejectCause">
            <Input
              :maxlength="100"
              v-model.trim="rejectForm.rejectCause"
              placeholder="请输入驳回原因，限100字"
            ></Input>
          </FormItem>
        </Form>
      </template>
      <template #toolsbar>
        <Button
          type="info"
          style="margin-right: 10px"
          @click="rejectStatusFn(false)"
          >取消</Button
        >
        <Button type="primary" @click="saveReject">确定</Button>
      </template>
    </LiefengModal>
  </div>
</template> 

<script>
//@route('/discussionindex')
import LiefengContent from "@/components/LiefengContent";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import DiscussNotice from "@/views/discussion/childrens/discussNotice";
import Discussvote from "@/views/discussion/childrens/discussvote";
import NextDiscussVote from "@/views/discussion/childrens/nextDiscussVote";
import NextDiscussAnn from "@/views/discussion/childrens/nextDiscussAnn";
import BaseInfo from "@/views/discussion/childrens/baseInfo";
export default {
  data() {
    return {
      tableData: [],
      tableHeight: "",
      talbeColumns: [
        {
          title: "标题",
          key: "title",
          minWidth: 200,
          align: "center",
        },
        {
          title: "所属社区",
          key: "communityName",
          width: 160,
          align: "center",
        },
        {
          title: "发布范围",
          key: "regions",
          width: 160,
          align: "center",
          render: (h, params) => {
            return h(
              "Poptip",
              {
                props: {
                  placement: "right",
                  width: "500",
                  wordWrap: true,
                  transfer: true,
                },
              },
              [
                h(
                  "Button",
                  {
                    props: {
                      type: "success",
                      size: "small",
                    },
                  },
                  "查看范围"
                ),
                h(
                  "div",
                  {
                    slot: "content",
                    style: {
                      textAlign: "left",
                    },
                  },
                  (() => {
                    let result = [];
                    params.row.regions.map((item) => {
                      result.push(
                        h(
                          "Button",
                          {
                            style: {
                              margin: "5px",
                            },
                          },
                          item.regionName
                        )
                      );
                    });
                    return result;
                  })()
                ),
              ]
            );
          },
        },
        {
          title: "创建时间",
          key: "gmtCreate",
          width: 180,
          align: "center",
        },
        {
          title: "当前阶段",
          key: "businessType",
          width: 160,
          align: "center",
        },
        {
          title: "状态",
          key: "status",
          width: 120,
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          render: (h, params) => {
            return h(
              "div",
              {
                style: {
                  textAlign: "left",
                },
              },
              [
                h(
                  "Button",
                  {
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    on: {
                      click: () => {
                        this.businessId = params.row.businessId;
                        this.currentMenuName = "baseInfo";
                        this.startend = "start";
                        switch (params.row.status) {
                          case "待提交":
                            this.infoStatus = "1";
                            break;
                          case "待审核":
                            this.infoStatus = "2";
                            break;
                          case "待发布":
                            this.infoStatus = "3";
                            break;
                          case "已发布":
                            this.infoStatus = "4";
                            break;
                          case "已结束":
                            this.infoStatus = "5";
                            break;
                          case "审核驳回":
                            this.infoStatus = "6";
                            break;
                          case "发布驳回":
                            this.infoStatus = "7";
                            break;
                        }
                        this.baseInfoStatus = true;
                      },
                    },
                    style: {
                      width: "65px",
                      marginRight: "10px",
                      textAlign: "center",
                      display: this.buttonRoot == "1001" ? "" : "none",
                    },
                  },
                  "修改"
                ),
                h(
                  "Button",
                  {
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    on: {
                      click: () => {
                        this.businessId = params.row.businessId;
                        this.currentMenuName = "baseInfo";
                        this.startend = "start";
                        switch (params.row.status) {
                          case "待提交":
                            this.infoStatus = "1";
                            break;
                          case "待审核":
                            this.infoStatus = "2";
                            break;
                          case "待发布":
                            this.infoStatus = "3";
                            break;
                          case "已发布":
                            this.infoStatus = "4";
                            break;
                          case "已结束":
                            this.infoStatus = "5";
                            break;
                          case "审核驳回":
                            this.infoStatus = "6";
                            break;
                          case "发布驳回":
                            this.infoStatus = "7";
                            break;
                        }
                        this.baseInfoStatus = true;
                      },
                    },
                    style: {
                      width: "65px",
                      marginRight: "10px",
                      textAlign: "center",
                      display:
                        this.buttonRoot == "1002" &&
                        params.row.status == "待审核"
                          ? ""
                          : "none",
                    },
                  },
                  "审核"
                ),
                h(
                  "Button",
                  {
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    on: {
                      click: () => {
                        this.businessId = params.row.businessId;
                        this.currentMenuName = "baseInfo";
                        this.startend = "start";
                        switch (params.row.status) {
                          case "待提交":
                            this.infoStatus = "1";
                            break;
                          case "待审核":
                            this.infoStatus = "2";
                            break;
                          case "待发布":
                            this.infoStatus = "3";
                            break;
                          case "已发布":
                            this.infoStatus = "4";
                            break;
                          case "已结束":
                            this.infoStatus = "5";
                            break;
                          case "审核驳回":
                            this.infoStatus = "6";
                            break;
                          case "发布驳回":
                            this.infoStatus = "7";
                            break;
                        }
                        this.baseInfoStatus = true;
                      },
                    },
                    style: {
                      width: "65px",
                      marginRight: "10px",
                      textAlign: "center",
                      display:
                        this.buttonRoot == "1003" &&
                        params.row.status == "待发布"
                          ? ""
                          : "none",
                    },
                  },
                  "发布"
                ),
                h(
                  "Dropdown",
                  {
                    props: {
                      transfer: true,
                    },
                    style: {
                      marginRight: "10px",
                      display: this.buttonRoot == "1001" ? "" : "none",
                    },
                  },
                  [
                    h(
                      "Button",
                      {
                        props: {
                          type: "success",
                          size: "small",
                          icon: "ios-arrow-down",
                        },
                      },
                      "下一阶段"
                    ),
                    h(
                      "DropdownMenu",
                      {
                        slot: "list",
                      },
                      [
                        h(
                          "DropdownItem",
                          {
                            nativeOn: {
                              click: () => {
                                this.parentBusinessId = params.row.businessId;
                                this.nextDiscussNoticeStatus = true;
                              },
                            },
                            style: {
                              textAlign: "center",
                            },
                          },
                          "新增议事讨论"
                        ),
                        h(
                          "DropdownItem",
                          {
                            nativeOn: {
                              click: () => {
                                this.parentBusinessId = params.row.businessId;
                                this.nextDiscussVoteStatus = true;
                              },
                            },
                            style: {
                              textAlign: "center",
                            },
                          },
                          "新增议事投票"
                        ),
                        h(
                          "DropdownItem",
                          {
                            nativeOn: {
                              click: () => {
                                this.parentBusinessId = params.row.businessId;
                                this.nextDiscussAnnStatus = true;
                              },
                            },
                            style: {
                              textAlign: "center",
                            },
                          },
                          "新增议事公告"
                        ),
                      ]
                    ),
                  ]
                ),
                h(
                  "Dropdown",
                  {
                    props: {
                      transfer: true,
                    },
                  },
                  [
                    h(
                      "Button",
                      {
                        props: {
                          type: "success",
                          size: "small",
                          icon: "ios-arrow-down",
                        },
                      },
                      "编辑"
                    ),
                    h(
                      "DropdownMenu",
                      {
                        slot: "list",
                      },
                      [
                        h(
                          "DropdownItem",
                          {
                            nativeOn: {
                              click: () => {
                                this.$router.push({
                                  path: "discussionmanage",
                                  query: {
                                    businessId: params.row.businessId,
                                    menuId: this.$route.query.menuId,
                                    startend:
                                      params.row.status == "已结束"
                                        ? "end"
                                        : "start",
                                  },
                                });
                              },
                            },
                            style: {
                              textAlign: "center",
                            },
                          },
                          "议事管理"
                        ),
                        h(
                          "DropdownItem",
                          {
                            nativeOn: {
                              click: () => {
                                this.$Modal.confirm({
                                  title: "温馨提示",
                                  content:
                                    "<p>您正在删除议事厅信息，此操作不可逆，是否继续</p>",
                                  onOk: () => {
                                    this.$get(
                                      "/sendmsg/pc/chat/discussBusiness/deleteByBusinessId",
                                      {
                                        businessId: params.row.businessId,
                                      }
                                    )
                                      .then((res) => {
                                        if (res.code == 200) {
                                          this.$Message.success({
                                            background: true,
                                            content: "删除成功",
                                          });
                                          this.hadlePageSize({
                                            page: this.page,
                                            pageSize: this.pageSize,
                                          });
                                        } else {
                                          this.$Message.error({
                                            background: true,
                                            content: res.desc,
                                          });
                                        }
                                      })
                                      .catch((err) => {
                                        console.log(err);
                                        this.$Message.error({
                                          background: true,
                                          content: "删除失败，请联系管理员处理",
                                        });
                                      });
                                  },
                                });
                              },
                            },
                            style: {
                              textAlign: "center",
                            },
                          },
                          "删除"
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            );
          },
        },
      ],
      loading: false,
      pageSize: 20,
      total: 0,
      page: 1,
      title: "",
      status: "1,2,3,4,6,7",

      // 控制三级审核
      buttonRoot: "",
      buttonStatus: "",

      // 新增议事讨论和新增议事公告
      discussNoticeTitle: "",
      discussNoticeStatus: false,
      cancelModalTip: false,
      businessType: "",

      // 新增议事投票
      discussVoteStatus: false,
      backNextText: "下一步",

      // 新增下一阶段——讨论
      nextDiscussNoticeStatus: false,
      discussNoticeTime: [],
      parentBusinessId: "",

      //  新增下一阶段——投票
      nextDiscussVoteStatus: false,

      // 新增下一阶段——公告
      nextDiscussAnnStatus: false,

      // 修改基本内容
      baseInfoStatus: false,
      currentMenuName: "baseInfo",
      businessId: "",
      startend: "",
      infoStatus: "",

      // 议事投票驳回
      rejectStatus: false,
      rejectForm: { rejectCause: "" },
      rejectValidate: {
        rejectCause: [
          { required: true, message: "请输入驳回原因", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    rejectStatusFn(status) {
      if (!status) {
        this.rejectForm = { rejectCause: "" };
      }
      this.rejectStatus = status;
    },
    openReject(businessId, status) {
      this.rejectForm.businessId = businessId;
      this.rejectForm.status = status;
      this.$refs.rejectForm.resetFields();
      this.rejectStatus = true;
    },
    saveReject() {
      this.$refs.rejectForm.validate((status) => {
        if (status) {
          this.$post("/sendmsg/pc/chat/discussBusiness/updateStatus", {
            ...this.rejectForm,
            staffId: parent.vue.loginInfo.userinfo.custGlobalId,
            staffName: parent.vue.loginInfo.userinfo.realName,
          }).then((res) => {
            if (res.code == 200) {
              this.$Message.success({
                background: true,
                content: "操作成功",
              });
              setTimeout(() => {
                this.$Message.destroy();
                this.rejectStatusFn(false);
                this.baseInfoStatusFn(false);
                this.hadlePageSize({
                  page: this.page,
                  pageSize: this.pageSize,
                });
              }, 500);
            } else {
              this.$Message.error({
                background: true,
                content: res.desc,
              });
            }
          });
        }
      });
    },
    // 通过议事
    updateVoteFn(businessId, status) {
      this.$post("/sendmsg/pc/chat/discussBusiness/updateStatus", {
        businessId: businessId,
        staffId: parent.vue.loginInfo.userinfo.custGlobalId,
        staffName: parent.vue.loginInfo.userinfo.realName,
        status: status,
      }).then((res) => {
        if (res.code == 200) {
          this.$Message.success({
            background: true,
            content: "操作成功",
          });
          setTimeout(() => {
            this.$Message.destroy();
            this.baseInfoStatusFn(false);
            this.hadlePageSize({
              page: this.page,
              pageSize: this.pageSize,
            });
          }, 500);
        } else {
          this.$Message.error({
            background: true,
            content: res.desc,
          });
        }
      });
    },
    // 修改基本内容
    backNextStep() {
      this.currentMenuName =
        this.currentMenuName == "baseInfo" ? "group" : "baseInfo";
    },

    baseInfoStatusFn(status) {
       if (
        this.$route.query.businessId &&
        this.$route.query.menuId &&
        this.$route.query.status &&
        !status
      ) {
        var index = parent.layer.getFrameIndex(window.name); //先得到当前iframe层的索引
        parent.layer.close(index); //再执行关闭
      } else if (!status && this.cancelModalTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "您正在关闭此窗口，信息将不会保存，是否继续",
          onOk: () => {
            this.baseInfoStatus = status;
          },
        });
      } else {
        this.baseInfoStatus = status;
      }
    },
    saveBaseInfo() {
      this.$refs.baseInfo.saveBaseInfo();
    },
    saveSuccessBaseInfo() {
      this.cancelModalTip = false;
      this.baseInfoStatusFn(false);
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize,
      });
    },

    saveNextSuccess() {
      this.cancelModalTip = false;
      this.nextDiscussVoteStatus = false;
      this.nextDiscussAnnStatus = false;
    },
    // 新增下一阶段——公告
    saveNextAnn() {
      this.$refs.nextDiscussAnn.save();
    },
    nextDiscussAnnStatusFn(status) {
      if (!status && this.cancelModalTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "您正在关闭此窗口，信息将不会保存，是否继续",
          onOk: () => {
            this.nextDiscussAnnStatus = status;
          },
        });
      } else {
        this.nextDiscussAnnStatus = status;
      }
    },
    // 新增下一阶段——投票
    saveNextVote() {
      this.$refs.nextDiscussVote.save();
    },
    nextDiscussVoteStatusFn(status) {
      if (!status && this.cancelModalTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "您正在关闭此窗口，信息将不会保存，是否继续",
          onOk: () => {
            this.nextDiscussVoteStatus = status;
          },
        });
      } else {
        this.nextDiscussVoteStatus = status;
      }
    },
    // 新增下一阶段——讨论
    saveNextDiscussNotice() {
      if (!this.discussNoticeTime[0] || !this.discussNoticeTime[1]) {
        this.$Message.error({
          background: true,
          content: "请选择讨论开始时间和结束时间",
        });
        return;
      }
      this.$post("/sendmsg/pc/chat/discussBusiness/addNextDiscussBusiness", {
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        endTime: this.$core.formatDate(
          new Date(this.discussNoticeTime[1]),
          "yyyy-MM-dd hh:mm:ss"
        ),
        startTime: this.$core.formatDate(
          new Date(this.discussNoticeTime[0]),
          "yyyy-MM-dd hh:mm:ss"
        ),
        modifier: parent.vue.loginInfo.userinfo.realName,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        parentBusinessId: this.parentBusinessId,
      })
        .then((res) => {
          if (res.code == 200) {
            this.$Message.success({
              background: true,
              content: "下阶段议事讨论新增成功",
            });
            this.discussNoticeTime = [];
            this.nextDiscussNoticeStatus = false;
          } else {
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$Message.error({
            background: true,
            content: "新增失败，请联系管理员处理",
          });
        });
    },

    saveSuccess() {
      this.cancelModalTip = false;
      this.discussNoticeStatus = false;
      this.discussVoteStatus = false;
      this.hadlePageSize({
        page: this.page,
        pageSize: this.pageSize,
      });
    },
    // 保存议事投票
    saveDiscussVote() {
      this.$refs.discussVote.validateFn();
    },
    // 议事投票上下一步
    backNextFn() {
      if (this.backNextText == "下一步") {
        this.backNextText = "上一步";
        this.$refs.discussVote.showMenu = "setInfo";
      } else {
        this.backNextText = "下一步";
        this.$refs.discussVote.showMenu = "baseInfo";
      }
    },
    changeBackNextText(name) {
      this.backNextText = name;
    },
    // 新增议事投票
    discussVoteStatusFn(status) {
      if (!status && this.cancelModalTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "您正在关闭此窗口，信息将不会保存，是否继续",
          onOk: () => {
            this.discussVoteStatus = status;
          },
        });
      } else {
        this.discussVoteStatus = status;
      }
    },
    // 保存新增议事讨论
    saveDiscussNotice() {
      this.$refs.discussNotice.validateFn();
    },
    // 新增议事讨论和新增议事公告
    discussNoticeStatusFn(status) {
      if (!status && this.cancelModalTip) {
        this.$Modal.confirm({
          title: "温馨提示",
          content: "您正在关闭此窗口，信息将不会保存，是否继续",
          onOk: () => {
            this.discussNoticeStatus = status;
          },
        });
      } else {
        this.discussNoticeStatus = status;
      }
    },
    search() {
      this.hadlePageSize({
        page: 1,
        pageSize: this.pageSize,
      });
    },
    //切换tab
    taggleTab(name) {
      if (name == "name1") {
        this.status = "1,2,3,4,6,7";
        this.talbeColumns.splice(this.talbeColumns.length - 1, 1, {
          title: "操作",
          align: "center",
          render: (h, params) => {
            return h(
              "div",
              {
                style: {
                  textAlign: "left",
                },
              },
              [
                h(
                  "Button",
                  {
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      width: "65px",
                      marginRight: "10px",
                      textAlign: "center",
                      display: this.buttonRoot == "1001" ? "" : "none",
                    },
                  },
                  "修改"
                ),
                h(
                  "Button",
                  {
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      width: "65px",
                      marginRight: "10px",
                      textAlign: "center",
                      display:
                        this.buttonRoot == "1002" &&
                        params.row.status == "待审核"
                          ? ""
                          : "none",
                    },
                  },
                  "审核"
                ),
                h(
                  "Button",
                  {
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      width: "65px",
                      marginRight: "10px",
                      textAlign: "center",
                      display:
                        this.buttonRoot == "1003" &&
                        params.row.status == "待发布"
                          ? ""
                          : "none",
                    },
                  },
                  "发布"
                ),
                h(
                  "Button",
                  {
                    props: {
                      type: "primary",
                      size: "small",
                    },
                    style: {
                      width: "65px",
                      marginRight: "10px",
                      textAlign: "center",
                      display:
                        this.buttonRoot == "1003" &&
                        params.row.status == "待归档"
                          ? ""
                          : "none",
                    },
                  },
                  "归档"
                ),
                h(
                  "Dropdown",
                  {
                    props: {
                      transfer: true,
                    },
                    style: {
                      marginRight: "10px",
                      display: this.buttonRoot == "1001" ? "" : "none",
                    },
                  },
                  [
                    h(
                      "Button",
                      {
                        props: {
                          type: "success",
                          size: "small",
                          icon: "ios-arrow-down",
                        },
                      },
                      "下一阶段"
                    ),
                    h(
                      "DropdownMenu",
                      {
                        slot: "list",
                      },
                      [
                        h(
                          "DropdownItem",
                          {
                            nativeOn: {
                              click: () => {
                                this.parentBusinessId = params.row.businessId;
                                this.nextDiscussNoticeStatus = true;
                              },
                            },
                            style: {
                              textAlign: "center",
                            },
                          },
                          "新增议事讨论"
                        ),
                        h(
                          "DropdownItem",
                          {
                            nativeOn: {
                              click: () => {
                                this.parentBusinessId = params.row.businessId;
                                this.nextDiscussVoteStatus = true;
                              },
                            },
                            style: {
                              textAlign: "center",
                            },
                          },
                          "新增议事投票"
                        ),
                        h(
                          "DropdownItem",
                          {
                            nativeOn: {
                              click: () => {
                                this.parentBusinessId = params.row.businessId;
                                this.nextDiscussAnnStatus = true;
                              },
                            },
                            style: {
                              textAlign: "center",
                            },
                          },
                          "新增议事公告"
                        ),
                      ]
                    ),
                  ]
                ),
                h(
                  "Dropdown",
                  {
                    props: {
                      transfer: true,
                    },
                  },
                  [
                    h(
                      "Button",
                      {
                        props: {
                          type: "success",
                          size: "small",
                          icon: "ios-arrow-down",
                        },
                      },
                      "编辑"
                    ),
                    h(
                      "DropdownMenu",
                      {
                        slot: "list",
                      },
                      [
                        h(
                          "DropdownItem",
                          {
                            nativeOn: {
                              click: () => {
                                this.$router.push({
                                  path: "discussionmanage",
                                  query: {
                                    businessId: params.row.businessId,
                                    menuId: this.$route.query.menuId,
                                    startend: "start",
                                  },
                                });
                              },
                            },
                            style: {
                              textAlign: "center",
                            },
                          },
                          "议事管理"
                        ),
                        h(
                          "DropdownItem",
                          {
                            nativeOn: {
                              click: () => {
                                this.$Modal.confirm({
                                  title: "温馨提示",
                                  content:
                                    "<p>您正在删除议事厅信息，此操作不可逆，是否继续</p>",
                                  onOk: () => {
                                    this.$get(
                                      "/sendmsg/pc/chat/discussBusiness/deleteByBusinessId",
                                      {
                                        businessId: params.row.businessId,
                                      }
                                    )
                                      .then((res) => {
                                        if (res.code == 200) {
                                          this.$Message.success({
                                            background: true,
                                            content: "删除成功",
                                          });
                                          this.hadlePageSize({
                                            page: this.page,
                                            pageSize: this.pageSize,
                                          });
                                        } else {
                                          this.$Message.error({
                                            background: true,
                                            content: res.desc,
                                          });
                                        }
                                      })
                                      .catch((err) => {
                                        console.log(err);
                                        this.$Message.error({
                                          background: true,
                                          content: "删除失败，请联系管理员处理",
                                        });
                                      });
                                  },
                                });
                              },
                            },
                            style: {
                              textAlign: "center",
                            },
                          },
                          "删除"
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            );
          },
        });
        if (this.buttonRoot == "1001") {
          this.talbeColumns[this.talbeColumns.length - 1].width = 290;
        } else {
          this.talbeColumns[this.talbeColumns.length - 1].width = 180;
        }
        this.search();
      } else {
        this.status = "5";
        this.talbeColumns.splice(this.talbeColumns.length - 1, 1, {
          title: "操作",
          align: "center",
          width: 170,
          render: (h, params) => {
            return h(
              "div",
              {
                style: {
                  textAlign: "center",
                },
              },
              [
                h(
                  "Button",
                  {
                    props: {
                      type: "success",
                      size: "small",
                    },
                    style: {
                      textAlign: "center",
                      marginRight: "10px",
                    },
                    on: {
                      click: () => {
                        this.$router.push({
                          path: "discussionmanage",
                          query: {
                            businessId: params.row.businessId,
                            menuId: this.$route.query.menuId,
                            startend: "end",
                          },
                        });
                      },
                    },
                  },
                  "议事管理"
                ),
                h(
                  "Button",
                  {
                    props: {
                      type: "error",
                      size: "small",
                    },
                    style: {
                      textAlign: "center",
                    },
                    on: {
                      click: () => {
                        this.$Modal.confirm({
                          title: "温馨提示",
                          content:
                            "<p>您正在删除议事厅信息，此操作不可逆，是否继续</p>",
                          onOk: () => {
                            this.$get(
                              "/sendmsg/pc/chat/discussBusiness/deleteByBusinessId",
                              {
                                businessId: params.row.businessId,
                              }
                            )
                              .then((res) => {
                                if (res.code == 200) {
                                  this.$Message.success({
                                    background: true,
                                    content: "删除成功",
                                  });
                                  this.hadlePageSize({
                                    page: this.page,
                                    pageSize: this.pageSize,
                                  });
                                } else {
                                  this.$Message.error({
                                    background: true,
                                    content: res.desc,
                                  });
                                }
                              })
                              .catch((err) => {
                                console.log(err);
                                this.$Message.error({
                                  background: true,
                                  content: "删除失败，请联系管理员处理",
                                });
                              });
                          },
                        });
                      },
                    },
                  },
                  "删除"
                ),
              ]
            );
          },
        });
        this.search();
      }
    },
    hadlePageSize(obj) {
      this.loading = true;
      this.$post("/sendmsg/pc/chat/discussBusiness/queryBusinessPage", {
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        page: obj.page,
        pageSize: obj.pageSize,
        status: this.status,
        title: this.title,
      })
        .then((res) => {
          if (res.code == 200 && res.dataList) {
            res.dataList.map((item) => {
              switch (item.businessType) {
                case "1":
                  item.businessType = "议事讨论";
                  break;
                case "2":
                  item.businessType = "议事投票";
                  break;
                case "3":
                  item.businessType = "议事公告";
                  break;
              }
              switch (item.status) {
                case "1":
                  item.status = "待提交";
                  break;
                case "2":
                  item.status = "待审核";
                  break;
                case "3":
                  item.status = "待发布";
                  break;
                case "4":
                  item.status = "已发布";
                  break;
                case "5":
                  item.status = "已结束";
                  break;
                case "6":
                  item.status = "审核驳回";
                  break;
                case "7":
                  item.status = "发布驳回";
                  break;
              }
              item.gmtCreate = item.gmtCreate
                ? this.$core.formatDate(
                    new Date(item.gmtCreate),
                    "yyyy-MM-dd hh:mm:ss"
                  )
                : "";
            });
            this.tableData = res.dataList;
            this.total = res.maxCount;
            this.pageSize = res.pageSize;
            this.page = res.currentPage;
            this.loading = false;
          } else {
            this.loading = false;
            this.$Message.error({
              background: true,
              content: res.desc,
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.$Message.error({
            background: true,
            content: "获取数据失败，请联系管理员处理",
          });
        });
    },
  },
  created() {
    let userButtonRoot = parent.vue.userButtonRoot;
    this.buttonRoot = userButtonRoot.includes("1001")
      ? "1001"
      : userButtonRoot.includes("1002")
      ? "1002"
      : userButtonRoot.includes("1003")
      ? "1003"
      : "";
    // this.buttonRoot = "1001";
    if (this.$route.query.businessId) {
      //从首页代办直接跳转到审核发布
      this.businessId = this.$route.query.businessId;
      this.currentMenuName = "baseInfo";
      this.startend = "start";
      this.infoStatus = this.$route.query.status;
      this.baseInfoStatus = true;
    } else {
      this.tableHeight = window.innerHeight - 172 + "";
      this.search();
      if (this.buttonRoot == "1001") {
        this.talbeColumns[this.talbeColumns.length - 1].width = 290;
      } else {
        this.talbeColumns[this.talbeColumns.length - 1].width = 180;
      }
    }
  },
  components: {
    LiefengContent,
    LiefengTable,
    LiefengModal,
    DiscussNotice,
    Discussvote,
    NextDiscussVote,
    NextDiscussAnn,
    BaseInfo,
  },
};
</script>
    
<style scoped lang='less'>
.discussNotice {
  /deep/.ivu-modal-close {
    display: none;
  }
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}

.modal {
  /deep/.ivu-table-wrapper {
    height: calc(100vh - 288px);
    /deep/.ivu-table {
      overflow: auto;
    }
  }
}
/deep/.ivu-table-tip {
  height: calc(100% - 39px);
  table {
    height: 100%;
  }
}
</style>